const PhoneNumberPicker = {
  name: 'Phone Number Picker',
  githubUrl: 'https://github.com/hughbe/phone-number-picker',
  description: 'An open source drop in phone number and country picker written in Swift for iOS. It is based off the WhatsApp account creation screen in terms of styling and functionality.',
  screenshots: 2,
  features: [
    'Add a simple picker controller with delegate methods enabling you to get feedback on user entry',
    'View the demo and example application as well as code documentation',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'Swift'
  }
};
export default PhoneNumberPicker;