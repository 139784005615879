const ClickBoard = {
  name: 'ClickBoard',
  category: 'Productivity & Utilities',
  price: '$1.99',
  itunesUrl: 'https://itunes.apple.com/gb/app/clickboard/id938205392',
  githubUrl: 'https://github.com/hughbe/clickboard',
  description: 'An app that converts your iPhone into a mouse and controller for your PC. Built as part of the Young Enterprise Scheme. The project website can be found <a href="http://clickboardapp.co.uk">here.</a>',
  shortDescription: 'An app that turns your computer into a mouse, keyboard, clicker and more.',
  downloads: '200+',
  screenshots: 3,
  features: [
    'Connect to the same WiFi network and start using ClickBoard',
    'ClickBoard samples your iPhone\'s exceptionally accurate accelerometer and gyroscope 48 times a second to calculate device movement',
    'Launch apps on your PC right from your device',
    'Control slideshows and presentations from your device',
    'Control playback of iTunes and your music library from your device',
    'Rotate your device to launch a multitouch trackpad to control your PC\'s mouse with your finger',
    'Type and enter keyboard commands into your PC from your device'
  ],
  technologies: {
    languages: 'Objective C, C and C#',
    frameworks: 'UIKit, Foundation, CoreFoundation, CoreMotion, .NET Framework, and Communicate',
    technologies: 'Auto Layout, Storyboards, Bonjour, Sockets and QRCodes'
  },
  milestones: 'Reached the Young Enterprise competition London finals, came 4th out of 83 adult teams in Richmond\'s Den, a seed capital competition.'
};
export default ClickBoard;