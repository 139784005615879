const Communicate = {
  name: 'Communicate',
  githubUrl: 'https://github.com/hughbe/communicate',
  description: 'An open source cross-platform library that aims to make it easy to connect Windows, Mac OSX and iOS devices to each other and send data between devices simply, reliably and effectively.',
  screenshots: 1,
  features: [
    'Supports iOS, Mac OSX and iOS with identical APIs',
    'Send any type of data between devices',
    'Add a header or footer to data and get updates on progress etc. on receiving and sending data',
    'Register new data types and change the way that Communicate operates by extending the library',
    'The library is designed to be used in a modular way - you can choose what functions Communicate runs',
    'View the demo and example application as well as code documentation',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'C# and Swift',
    frameworks: 'Foundation, CoreFoundation and .NET Framework',
    technologies: 'Sockets, TCP, Bonjour, Networking and Streams'
  }
};
export default Communicate;