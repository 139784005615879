import Work from './CV/Work';

const CV = {
  work: Work,
  sections: [
    'About',
    'Coding',
    'Open Source',
    'Academic',
    'Work & Internships',
    'Personal'
  ]
};
export default CV;