const DayDatePicker = {
  name: 'Day Date Picker',
  githubUrl: 'https://github.com/hughbe/day-date-picker',
  description: 'An open source drop in highly customizable date picker which displays the day of the week alongside the day number column written in Objective C for iOS.',
  screenshots: 2,
  features: [
    'Add a simple picker with delegate methods enabling you to get feedback on user entry and control the display of the picker',
    'Change how the picker is presented and displayed to the user',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'Swift'
  }
};
export default DayDatePicker;