const RemoteFileBrowser = {
  name: 'Remote File Browser',
  category: 'Productivity & Utilities',
  price: '$1.99',
  itunesUrl: 'https://itunes.apple.com/gb/app/remote-file-browser/id1002907027',
  githubUrl: 'https://github.com/hughbe/remote-file-browser',
  description: 'An app that lets you browse, open and edit files and folders on your computer straight from your iOS device.',
  shortDescription: 'An app that allows you to access, view and modify files on your PC from your phone.',
  downloads: '200+',
  screenshots: 3,
  features: [
    'Browse a list of all folders on your computer',
    'Open pictures, text files, word documents and all other files stored on your PC on your device',
    'Launch files, applications and folders on your PC from your device',
    'Rename, move, copy or delete files and folders on your PC from your device',
    'Add a file or folder to your favorites list to access it on demand from the sidebar',
    'Use on all iPhones, iTouches and iPads as well as any computer running a Mac or Windows operating system connected to your network'
  ],
  technologies: {
    languages: 'Objective C, C and C#',
    frameworks: 'UIKit, Foundation, CoreFoundation, .NET Framework and Communicate',
    technologies: 'Auto Layout, Storyboards, Bonjour and Sockets'
  },
  installationInstructions: [
    'Connect your iOS device and PC to the same WiFi network',
    'Download the software release on your computer and install/run the executable',
    'Make sure you allow the software access to your firewall if you are prompted, otherwise Remote File Browser will not be able to work',
    'Launch the software on your PC and open the Remote File Browser app on your device',
    'Tap on the name of your computer and start browsing!'
  ]
};
export default RemoteFileBrowser;