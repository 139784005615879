import HomeworkPlanner from './Apps/HomeworkPlanner';
import ClickBoard from './Apps/ClickBoard';
import RemoteFileBrowser from './Apps/RemoteFileBrowser';
import Canvas from './Apps/Canvas';
import SpeakEasy from './Apps/SpeakEasy';

const Apps = [
  HomeworkPlanner,
  ClickBoard,
  RemoteFileBrowser,
  Canvas,
  SpeakEasy
];
export default Apps;