const SpeakEasy = {
  name: 'Speak Easy',
  category: 'Entertainment & Utilities',
  price: '$0.99',
  itunesUrl: 'https://itunes.apple.com/gb/app/speak-easy/id716763156',
  githubUrl: 'https://github.com/hughbe/speak-easy',
  description: 'An app that converts text to speech in an incredibly configurable way.',
  shortDescription: 'An interactive text-to-speech generator.',
  downloads: '600+',
  screenshots: 3,
  features: [
    'Convert text to speech',
    'Customize the pitch, speed and accent of the generated speech',
    'Save customizations as presets to use later',
    'Share presets and funny entries to your friends, family or the world on the internet'
  ],
  technologies: {
    languages: 'Objective C',
    frameworks: 'UIKit and Foundation',
    technologies: 'Auto Layout, Storyboards, Networking and Social Sharing'
  },
  milestones: 'Featured on the App Store in 27 countries upon release'
};
export default SpeakEasy;