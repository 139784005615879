import PhoneNumberPicker from './Open Source Projects/PhoneNumberPicker';
import DayDatePicker from './Open Source Projects/DayDatePicker';
import HtmlGenerator from './Open Source Projects/HtmlGenerator';
import VersionChecker from './Open Source Projects/VersionChecker';
import Communicate from './Open Source Projects/Communicate';
import HttpServer from './Open Source Projects/HttpServer';
import Notifications from './Open Source Projects/Notifications';
import SemiModalController from './Open Source Projects/SemiModalController';

const OpenSourceProjects = [
  PhoneNumberPicker,
  DayDatePicker,
  HtmlGenerator,
  VersionChecker,
  Communicate,
  HttpServer,
  Notifications,
  SemiModalController
];
export default OpenSourceProjects;