const HtmlGenerator = {
  name: 'Html Generator',
  githubUrl: 'https://github.com/hughbe/html-generator',
  description: 'An open source library that simplifies generating complex HTML files from C# code. In fact, this site was generated using this library.',
  screenshots: 1,
  features: [
    'Use method chaining to create complex HTML trees and change elements',
    'Use all HTML tags and attributes and create your own',
    'Add any attribute and content to all HTML elements, including classes, ids and styles',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'C#'
  }
};
export default HtmlGenerator;