import React from 'react';
import './css/HomePage.css';

const SapphPage = () => {
  return (
    <main className="home-page">
      <div className="home-page-content">
        <h1 className="introduction">HI THERE FSG</h1>
        <button onClick={() => alert("YUP. For a long time too.")}>Do there be lava?</button>
        <p>
        <a href="https://onlyfans.com/hbsfsg">[Click Me After] What would it take to last forever?</a>
        </p>
      </div>
    </main>
  );
};
export default SapphPage;