const Work = [
  {
    name: 'HSBC',
    url: 'https://www.hsbc.co.uk/',
    details: [
      {
        title: 'Summer Analyst',
        duration: 'July - August 2020',
        description: 'Summer Analyst in the Financial Institutions Group (FIG) team. Conducted training in finance, financial modelling and accounting. Worked on pitch decks, company profiles and modelling.'
      }
    ]
  },
  {
    name: 'Basis Research',
    url: 'https://www.basisresearch.com/',
    details: [
      {
        title: 'Intern',
        duration: 'September - October 2019',
        description: 'Performing and analysing market research including focus groups, workshops and online communities. Clients I worked with include Google, Netflix and BT.'
      }
    ]
  },
  {
    name: 'The Oxford Union',
    url: 'https://www.oxford-union.org/',
    details: [
      {
        title: 'Director of Digital Operations',
        duration: 'Hilary Term 2019 - Michaelmas Term 2019',
        description: 'Leading the development of the Oxford Union App, managing the Facebook page and website. Personally responsible for inviting the Prime Minister of Serbia and the President of Montenegro.'
      },
      {
        title: 'Secretary\'s Committee',
        duration: 'Michaelmas Term 2018',
        description: 'Inviting speakers, facilitating events and leading the development of the Oxford Union App.'
      },
      {
        title: 'Logistics Officer',
        duration: 'Trinity Term 2018',
        description: 'Assisting the President and the Chair of the Consultative Committee in managing, organizing and facilitating speaker events, panels and debates.'
      }
    ]
  },
  {
    name: 'The Asia Foundation',
    url: 'http://asiafoundation.org/',
    blurb: 'Working in the startup program section of the NGO in Cambodia.',
    details: [
      {
        title: 'Intern',
        location: 'Phnom Penh, Cambodia',
        duration: '6 months, February 2017 - August 2017',
        description: `Projects I worked on include:
        <div class="job-details">
          <div class="job-detail">
            <h3>AsiaChat</h3>
            <p>Collecting and analysing Facebook posts in Cambodia and Laos on multiple political and media pages, to expand on viewpoints in civil society.</p>
          </div>
          <div class="job-detail">
            <h3><a href="http://tekhub.co">TEKHUB</a></h3>
            <p>Managing logistics, organising, marketing and hosting events at an incubator for social enterprise startups in technology.</p>
          </div>
          <div class="job-detail">
            <h3>Bong Srey</h3>
            <p>Developing and marketing a job website for the 700,000 women in the Cambodian textile industry.</p>
          </div>
        </div>
        `
      }
    ]
  },
  {
    name: 'SYZYGY',
    url: 'https://www.syzygy.co.uk/',
    details: [
      {
        title: 'Junior Developer',
        location: 'London, United Kingdom',
        duration: '4 months, October 2016 - January 2017',
        description: 'ASP.NET Core backend development of milk delivery service <a href="https://milkman.co.uk">Milk and More</a>, responsible for customer data, managing and triggering deliveries and billing of subscriptions and addons.'
      }
    ]
  },
  {
    name: 'Accenture',
    url: 'https://www.accenture.com/',
    details: [
      {
        location: 'London, United Kingdom',
        duration: '1 week, August 2016',
        description: 'Drafted and developed an internal website for the Analyst Consulting Group, and learnt about management consulting. Discovered how Accenture structures its business and uses technology to help clients.'
      }
    ]
  },
  {
    name: 'Young Enterprise',
    url: 'https://www.young-enterprise.org.uk/',
    details: [
      {
        duration: '1 academic year, 2014 - 2015',
        description: 'Head of IT, product design and product development of the app ClickBoard.'
      }
    ]
  },
  {
    name: 'JP Morgan',
    url: 'https://www.jpmorgan.com/',
    details: [
      {
        location: 'Bournemouth, United Kingdom',
        duration: '1 week, July 2015',
        description: 'Shadowed a graduate working in the commodities trading back office. Learnt about how commodities trading operates and how a global investment bank is structured.'
      }
    ]
  },
  {
    name: 'National Institute for Economic and Social Research',
    url: 'https://www.niesr.ac.uk/',
    details: [
      {
        location: 'London, United Kingdom',
        duration: '1 week, July 2015',
        description: 'Developed software named Can You Buy Happiness with an academic to research the effect of relative income and perceptions of relative income on happiness. Developed skills in statistical analysis and econometrics.'
      }
    ]
  },
  {
    name: 'European Union Delegation to Serbia',
    url: 'http://www.seio.gov.rs/',
    details: [
      {
        location: 'Belgrade, Serbia',
        duration: '1 week, October 2015',
        description: 'Researched my EPQ and discovered more about how the EU interacts with the Serbian government to meet requirements for EU membership.'
      }
    ]
  }
];
export default Work;