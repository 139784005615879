const Canvas = {
  name: 'Canvas',
  category: 'Entertainment & Utilities',
  price: '$0.99',
  itunesUrl: 'https://itunes.apple.com/gb/app/canvas/id777551805',
  githubUrl: 'https://github.com/hughbe/canvas',
  description: 'A fully customizable drawing interface suited to all your artistic desires and expressions: the only thing limiting you is your artistic ability.',
  shortDescription: 'A customizable video, photo, animation and drawing interface.',
  downloads: '1,500+',
  screenshots: 3,
  features: [
    'Draw in any color and any width',
    'Change the opacity of your writings and configure blend modes in your canvasses',
    'Change the shape of your brush - square, rounded etc.',
    'Add a variety of shapes, line dashes and letters to your canvases',
    'Add textures (such as brick, rock, concrete, wood) to your canvasses to increase realism and expand your drawing options',
    'Draw on pictyres, video on a custom sized and colored blank canvases',
    'Redo, undo, move, delete and fill previous actions',
    'Save and share canvasses with a name to your phone'
  ],
  technologies: {
    languages: 'Objective C',
    frameworks: 'UIKit, CoreAnimation, CoreGraphics, Foundation and CoreData',
    technologies: 'Auto Layout, Storyboards and In-App Purchases'
  },
  milestones: '26th most downloaded app in category in South Korea (04.02.14)'
};
export default Canvas;