const HttpServer = {
  name: 'Http Server',
  githubUrl: 'https://github.com/hughbe/http-server',
  description: 'An open source versatile local and remote HTTP web server written in C#.',
  screenshots: 1,
  features: [
    'Change the root directory and server port all from an easy to use GUI',
    'Great for abstracting URLs and as a versatile local server',
    'Run or install the application and the server starts up instantly, even on startup if you want',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'C#',
    frameworks: 'ASP.NET'
  },
  installationInstructions: [
    'Download the software release for your computer and install/run the executable',
    'Make sure to allow the software access to your firewall if you are prompted',
    'Launch the software on your PC and then open HTTP Server on your computer',
    'Go to your web browser and type http://localhost/ to start browsing',
    'You can access the server\'s settings by clicking on the HTTP Server icon in your taskbar'
  ]
};
export default HttpServer;