const VersionChecker = {
  name: 'Version Checker',
  githubUrl: 'https://github.com/hughbe/version-checker',
  description: 'An open source drop-in library, written in C#, that simplifies version checking for applications of all types.',
  screenshots: 1,
  features: [
    'Add one .cs file to your project, type two lines of code to get a fully functional version checker',
    'Add a date, (release) notes, a url and more to releases and updates',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'C#'
  }
};
export default VersionChecker;