const HomeworkPlanner = {
  name: 'Homework Planner',
  category: 'Productivity & Education',
  price: 'Free',
  itunesUrl: 'https://itunes.apple.com/gb/app/homework-planner/id505295949',
  githubUrl: 'https://github.com/hughbe/homework-planner',
  description: 'Homework and School Planner is the ideal app for students of all ages at school or university to stay organised and simplify your working life.',
  shortDescription: 'A homework planner and timetable for students.',
  downloads: '200,000+',
  screenshots: 3,
  features: [
    'View all pieces and homework and lessons in the future using the calendar view',
    'View all your pieces of homework by subject',
    'Search for pieces of homework by subject, content or teacher',
    'Add to and view attachments (photos and websites) for individual pieces of homework',
    'Get notified before your piece of homework is due in',
    'Set the type of homework you have been set, be it revision, essay, exercise etc.',
    'View your school timetable as well as pieces of homework',
    'View a two week timetable if necessary',
    'Color code subjects',
    'Set multiple teachers for the same subject',
    'A consistent, elegant and easy-to-use design that adapts to all devices'
  ],
  technologies: {
    languages: 'Objective C',
    frameworks: 'UIKit, CoreAnimation, Foundation, CoreData and EventKit',
    technologies: 'Auto Layout, Storyboards and In-App Purchases'
  },
  milestones: '71st most downloaded app in category in UK, Top 100 in category in 45 countries (05.09.12)'
};
export default HomeworkPlanner;