const SemiModalController = {
  name: 'Semi Modal Controller',
  githubUrl: 'https://github.com/hughbe/semi-modal-controller',
  description: 'An open source library, written in Objective C for iOS, that allows you to present a UIViewController semi-modally (over the current content). Designed in a modular way and with subclassing in mind, this library lets you present view controllers that you can slide, zoom, dismiss and subclass.',
  screenshots: 2,
  features: [
    'Add a a pretty and highly customizable library that provides a way of presenting view controllers semi-modally',
    'View the demo and example application as well as code documentation',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'Swift'
  }
};
export default SemiModalController;