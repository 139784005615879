const Notifications = {
  name: 'Notifications',
  githubUrl: 'https://github.com/hughbe/notifications',
  description: 'A drop-in compilation of code files across platforms and programming languages that simplify posting notifications of various styles to the end user.',
  screenshots: 1,
  features: [
    'Add only two lines of code to get a fully functional notifications manager into your app',
    'Change the tooltip text and icon of your tasbar icon as well as the text, content, duration and icon of notifications',
    'Add buttons with click callback actions to the context menu of your taskbar icon (Windows only)',
    'This project is open source. You can view, change and contribute to the source code on GitHub'
  ],
  technologies: {
    languages: 'C# and Objective C'
  }
};
export default Notifications;